import * as React from "react"
import Layout from "../components/common/layout"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Title from "../components/title"
import Hero from "../components/hero"
import Heading from "../components/common/heading"
import PreFooter from "../components/pre-footer"
import PartnerBanner from "../components/partner-banner"
import ListVideoSlider from "../components/list-videos-slider-mobile"
// .slick-list
// markup 
const VideoHub = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const videos = _data.find((item: any) => item.block === 'videos')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero')
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataPartnerBanner = _data.find((item: any) => item.block === 'partnerBanner')
  const link = {
    "link": "/video-main-category",
    "text": "View all",
  }
  return (
    <Layout>
      <Helmet
        title='Video hub'
      />
      <Hero background="bg-blue-white" data={hero.data[0]} container={true} noSlider={true}></Hero>
      <Title data={dataTitle.data[0]} background="bg-white"  standAlone={true} ></Title>
      <Heading 
        link= {link}
        title='Fitness' 
        background='bg-white'  
        />
    
      <ListVideoSlider  data={videos.data[0]} ShowDescription={true}/>
      <Heading 
        link= {link}
        title='Mind & Mood' 
        background='bg-white'  
        />
      <ListVideoSlider  data={videos.data[0]} ShowDescription={true}/>
      <Heading 
        link= {link}
        title='Nutrition' 
        background='bg-white'  
        />
      <ListVideoSlider  data={videos.data[0]} ShowDescription={true}/>
      <PartnerBanner data={dataPartnerBanner.data[0]} background="bg-green" ></PartnerBanner>
      <PreFooter data={dataPrefooter.data[0]} background="bg-blue"></PreFooter>  
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["video-hub"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
              links{
                id
                text
                url
              }
            }
            partner_banner {
              content
              link {
                id
                text
                url
              }
              logo
              partner_image
            }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
              }
            } 
            videos {
              id
              title
              link
              image
              video {
                length
                level
                name
                tag
              }
            }
          }
        }
      }
    }
  }
`
export default VideoHub
